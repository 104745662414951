import React, {forwardRef} from 'react';
import * as styles from './index.module.scss';

const AspectRatioContainer = ({children, aspectRatio}, ref) => {
    if (!aspectRatio) {
        return <pre>Please input an aspect ratio e.g. 16/9 </pre>;
    }
    const tempArr = aspectRatio.split('/').map(item => parseInt(item)).sort((a,b) => a-b);
    const aspectPercentage = (tempArr[0]/tempArr[1] * 100);
    return <div ref={ref} className={styles.wrapper} style={{paddingBottom: `${aspectPercentage}%`}}>
        {children}
    </div>;
};

export default forwardRef(AspectRatioContainer);