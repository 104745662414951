import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import WipeLink from '../../nav-transitions/wipe-link';
import ArrowRight from '../../arrow-right';
const Header = (
  {
    clientTitle,
    description,
    completed,
    timeframe,
    textColor,
    website,
    clientLogo,
    backgroundColor,
    backgroundImage,
  },
  ref
) => {
  let customStyle = {
    backgroundColor: backgroundColor,
  };

  if (backgroundImage) {
    customStyle.backgroundImage = `url(${backgroundImage})`;
  }

  let colorStyle = {};

  if (textColor) {
    colorStyle.color = textColor;
  }

  return (
    <div
      ref={ref}
      className={`${styles.headerWrapper} header`}
      style={customStyle}
    >
      <WipeLink to={'/our-work'}>
        <div style={{transform: 'scaleX(-1) translateX(30px)'}}>
         <ArrowRight color={textColor} onClick={() => {}}/>
        </div>
      </WipeLink>
      <img className={`anim ${styles.clientLogo}`} alt='' src={clientLogo} />
      <div className={`${styles.content}`}>
        <p className={`${styles.description} anim title-L`} style={colorStyle}>
          {' '}
          {description}
        </p>

        <div className={`${styles.overview}`}>
          <div className={`${styles.overviewBlock} anim`}>
            <h5
              className={`${styles.label}  utility-overline-heavy`}
              style={colorStyle}
            >
              {' '}
              Client{' '}
            </h5>
            <h5 className={`${styles.clientName} title-XS`} style={colorStyle}>
              {clientTitle}
            </h5>
          </div>

          <div className={`${styles.overviewBlock} anim`}>
            <h5
              className={`${styles.label} utility-overline-heavy`}
              style={colorStyle}
            >
              {' '}
              Roadmap{' '}
            </h5>
            <h5 className={`${styles.clientName} title-XS`} style={colorStyle}>
              {completed}
            </h5>
          </div>

          <div className={`${styles.overviewBlock} anim`}>
            <h5
              className={`${styles.label} utility-overline-heavy`}
              style={colorStyle}
            >
              {' '}
              Timeframe{' '}
            </h5>
            <h5 className={`${styles.clientName} title-XS`} style={colorStyle}>
              {timeframe}
            </h5>
          </div>

          <div className={`${styles.overviewBlock} anim`}>
            <h5
              className={`${styles.label} utility-overline-heavy`}
              style={colorStyle}
            >
              {' '}
              Website{' '}
            </h5>
            <a
              className={`${styles.clientWebsite} title-XS`}
              href={website}
              rel='noreferrer'
              target={'_blank'}
              style={colorStyle}
            >
              {website.split('https://')[1]}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Header);
