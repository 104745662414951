import React, { forwardRef, useEffect, useState, useRef } from 'react';
import * as styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';

import Footer from '../footer';
import Header from '../case-study-components/header';
import TextBlockOption1 from '../case-study-components/text-block-option1';
import TextBlockOption2 from '../case-study-components/text-block-option2';
import TextBlockOption3 from '../case-study-components/text-block-option3';
import TextBlockOption4 from '../case-study-components/text-block-option4';
import TextBlockOption5 from '../case-study-components/text-block-option5';
import DesktopMobileOption1 from '../case-study-components/desktop-mobile-option1';
import DesktopMobileOption2 from '../case-study-components/desktop-mobile-option2';
import DesktopOption1 from '../case-study-components/desktop-option1';
import DesktopOption2 from '../case-study-components/desktop-option2';
import DesktopOption4 from '../case-study-components/desktop-option4';
import MobileOption1 from '../case-study-components/mobile-option1';
import MobileOption3 from '../case-study-components/mobile-option3';
import MobileOption6 from '../case-study-components/mobile-option6';
import Testimonial from '../case-study-components/testimonial';
import ImageSingle from '../case-study-components/image-single';
import ImageGrid1 from '../case-study-components/image-grid1';
import ImageGrid2 from '../case-study-components/image-grid2';
import YoutubeVideo from '../case-study-components/youtube-video';
import ScrollablePage from '../../layouts/scrollable-page';
import { getCaseStudies } from '../../helpers/axios';
import { setCaseStudies } from '../../state/caseStudies';
import useCombinedRefs from '../../hooks/useCombinedRefs';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import SEO from '../seo';
import useImagePreloader from '../../hooks/useImagePreloader';
import Preloader from '../preloader';
import { animateHeadComponent, animateComponentFadeIn } from './animations';
import gsap from 'gsap';

const CaseStudy = (_, ref) => {
  const rootRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
 
  const slugRef = useRef();
  const combinedRef = useCombinedRefs(ref);
  const caseStudy = useSelector(
    (state) =>
      state.caseStudies.caseStudies.find((cs) => cs.attributes.slug === slugRef.current) ||
      null
  );

    useEffect(() => {
      const parts = location.pathname.split('/').filter((part) => part !== '');
      const slug = parts[parts.length - 1];
      slugRef.current =  slug;
    }, []);

  const fetchCaseStudies = async () => {
    const response = await getCaseStudies();
    dispatch(setCaseStudies(response.data));
  };

  useEffect(() => {
    if (caseStudy === null) {
      fetchCaseStudies();
    } else {
      imagePreloader.startPreloading();
    }
  }, [caseStudy]);

  // fetches the urls and returns one flat array
  const getDynamicImagesForPreloading = () => {
    if (!caseStudy) return;
    const temp = caseStudy.attributes.caseStudyComponents.map((comp, i) => {
      switch (comp.__component) {
        case 'case-study.header-option-01':
          return [
            comp.clientLogo.data.attributes.url,
            comp.backgroundImage.data
              ? comp.backgroundImage.data.attributes.url
              : null,
          ];
        case 'case-study.desktop-and-mobile-media-block-option-01':
          return [
            comp.desktop.data.attributes.url,
            comp.mobile.data.attributes.url,
          ];
        case 'case-study.desktop-and-mobile-media-block-option-02':
          return [
            comp.desktop.data.attributes.url,
            comp.mobile1.data.attributes.url,
            comp.mobile2.data.attributes.url,
          ];
        case 'case-study.desktop-media-block-option-01':
          return [
            comp.desktop1.data.attributes.url,
            comp.desktop2.data.attributes.url,
            comp.desktop3.data.attributes.url,
            comp.desktop4.data.attributes.url,
            comp.desktop5.data.attributes.url,
            comp.desktop6.data.attributes.url,
          ];
        case 'case-study.desktop-media-block-option-02':
          return [comp.desktop.data.attributes.url];
        case 'case-study.desktop-media-block-option-04':
          return [
            comp.desktop1.data.attributes.url,
            comp.desktop2.data.attributes.url,
          ];
        case 'case-study.mobile-media-block-option-01':
          return [
            comp.mobile1.data.attributes.url,
            comp.mobile2.data.attributes.url,
            comp.mobile3.data.attributes.url,
          ];
        case 'case-study.mobile-media-block-option-03':
          return [
            comp.mobile1.data.attributes.url,
            comp.mobile2.data.attributes.url,
            comp.mobile3.data.attributes.url,
            comp.mobile4.data.attributes.url,
            comp.mobile5.data.attributes.url,
            comp.mobile6.data.attributes.url,
          ];
        case 'case-study.mobile-media-block-option-06':
          return [
            comp.mobile1.data.attributes.url,
            comp.mobile2.data.attributes.url,
            comp.mobile3.data.attributes.url,
          ];
        case 'case-study.image-single-layout-01':
          return [comp.image.data.attributes.url];
        case 'case-study.image-grid-layout-01':
          return [
            comp.image1.data.attributes.url,
            comp.image2.data.attributes.url,
            comp.image3.data.attributes.url,
            comp.image4.data.attributes.url,
          ];
        case 'case-study.image-grid-layout-02':
          return [
            comp.image1.data.attributes.url,
            comp.image2.data.attributes.url,
            comp.image3.data.attributes.url,
            comp.image4.data.attributes.url,
          ];
        case 'case-study.testimonial-block-option-01':
          return [
            comp.backgroundImage.data
              ? comp.backgroundImage.data.attributes.url
              : null,
          ];
      }
    });

    return temp.flat().filter((item) => item);
  };

  const imagePreloader = useImagePreloader(getDynamicImagesForPreloading());
  useEffect(() => {
    if (imagePreloader.isLoading) return;
    animateComponentFadeIn(rootRef, combinedRef);
    animateHeadComponent(rootRef);

    
  }, [imagePreloader.isLoading]);

  const getDynamicComponent = (comp, index) => {
    switch (comp.__component) {
      case 'case-study.header-option-01':
        return (
          <Header
            key={`${comp.clientTitle}-${index}`}
            clientTitle={comp.clientTitle}
            description={comp.description}
            completed={comp.completed}
            timeframe={comp.timeframe}
            textColor={comp.textColor}
            website={comp.website}
            clientLogo={comp.clientLogo.data.attributes.url}
            backgroundColor={comp.backgroundColor}
            backgroundImage={
              comp.backgroundImage.data
                ? comp.backgroundImage.data.attributes.url
                : null
            }
          />
        );

      case 'case-study.text-block-option-01':
        return (
          <TextBlockOption1
            key={`${comp.clientTitle}-${index}`}
            title={comp.title}
            paragraph1={comp.paragraph1}
            paragraph2={comp.paragraph2}
          />
        );

      case 'case-study.text-block-option-02':
        return (
          <TextBlockOption2
            key={`${comp.clientTitle}-${index}`}
            title={comp.title}
            paragraph={comp.paragraph}
          />
        );

      case 'case-study.text-block-option-03':
        return (
          <TextBlockOption3
            key={`${comp.clientTitle}-${index}`}
            paragraph1={comp.paragraph1}
            paragraph2={comp.paragraph2}
          />
        );

      case 'case-study.text-block-option-04':
        return (
          <TextBlockOption4
            key={`${comp.clientTitle}-${index}`}
            paragraph1={comp.paragraph1}
            paragraph2={comp.paragraph2}
            paragraph3={comp.paragraph3}
          />
        );

      case 'case-study.text-block-option-05':
        return (
          <TextBlockOption5
            key={`${comp.clientTitle}-${index}`}
            paragraph={comp.paragraph}
          />
        );

      case 'case-study.desktop-and-mobile-media-block-option-01':
        return (
          <DesktopMobileOption1
            key={`${comp.clientTitle}-${index}`}
            border={comp.border}
            desktop={
              comp.desktop.data ? comp.desktop.data.attributes.url : null
            }
            mobile={comp.mobile.data.attributes.url}
          />
        );

      case 'case-study.desktop-and-mobile-media-block-option-02':
        return (
          <DesktopMobileOption2
            key={`${comp.clientTitle}-${index}`}
            border={comp.border}
            desktop={comp.desktop.data.attributes.url}
            mobile1={comp.mobile1.data.attributes.url}
            mobile2={comp.mobile2.data.attributes.url}
          />
        );

      case 'case-study.desktop-media-block-option-01':
        return (
          <DesktopOption1
            key={`${comp.clientTitle}-${index}`}
            desktop1={comp.desktop1.data.attributes.url}
            desktop2={comp.desktop2.data.attributes.url}
            desktop3={comp.desktop3.data.attributes.url}
            desktop4={comp.desktop4.data.attributes.url}
            desktop5={comp.desktop5.data.attributes.url}
            desktop6={comp.desktop6.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.desktop-media-block-option-02':
        return (
          <DesktopOption2
            key={`${comp.clientTitle}-${index}`}
            desktop={comp.desktop.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.desktop-media-block-option-04':
        return (
          <DesktopOption4
            key={`${comp.clientTitle}-${index}`}
            desktop1={comp.desktop1.data.attributes.url}
            desktop2={comp.desktop2.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.mobile-media-block-option-01':
        return (
          <MobileOption1
            key={`${comp.clientTitle}-${index}`}
            mobile1={comp.mobile1.data.attributes.url}
            mobile2={comp.mobile2.data.attributes.url}
            mobile3={comp.mobile3.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.mobile-media-block-option-03':
        return (
          <MobileOption3
            key={`${comp.clientTitle}-${index}`}
            mobile1={comp.mobile1.data.attributes.url}
            mobile2={comp.mobile2.data.attributes.url}
            mobile3={comp.mobile3.data.attributes.url}
            mobile4={comp.mobile4.data.attributes.url}
            mobile5={comp.mobile5.data.attributes.url}
            mobile6={comp.mobile6.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.mobile-media-block-option-06':
        return (
          <MobileOption6
            key={`${comp.clientTitle}-${index}`}
            mobile1={comp.mobile1.data.attributes.url}
            mobile2={comp.mobile2.data.attributes.url}
            mobile3={comp.mobile3.data.attributes.url}
            border={comp.border}
          />
        );

      case 'case-study.image-single-layout-01':
        return (
          <ImageSingle
            key={`${comp.clientTitle}-${index}`}
            image={comp.image.data.attributes.url}
          />
        );

      case 'case-study.image-grid-layout-01':
        return (
          <ImageGrid1
            key={`${comp.clientTitle}-${index}`}
            image1={comp.image1.data.attributes.url}
            image2={comp.image2.data.attributes.url}
            image3={comp.image3.data.attributes.url}
            image4={comp.image4.data.attributes.url}
          />
        );

      case 'case-study.image-grid-layout-02':
        return (
          <ImageGrid2
            key={`${comp.clientTitle}-${index}`}
            image1={comp.image1.data.attributes.url}
            image2={comp.image2.data.attributes.url}
            image3={comp.image3.data.attributes.url}
            image4={comp.image4.data.attributes.url}
          />
        );

      case 'case-study.testimonial-block-option-01':
        return (
          <Testimonial
            key={`${comp.clientTitle}-${index}`}
            title={comp.title}
            backgroundImage={
              comp.backgroundImage.data
                ? comp.backgroundImage.data.attributes.url
                : null
            }
            paragraph={comp.paragraph}
          />
        );

      case 'case-study.embedded-youtube-url':
        return <YoutubeVideo url={comp.videoURL} />;

      default:
        return null;
    }
  };
  return (
    <ScrollablePage hideGradient={true} simpleBarRef={combinedRef}>
      {caseStudy && caseStudy.attributes.SEO && (
        <SEO
          ignoreDefaults={true}
          keywords={
            caseStudy.attributes.SEO.keywords
              ? caseStudy.attributes.SEO.keywords
              : ''
          }
          preventIndexing={caseStudy.attributes.SEO.preventIndexing}
          shareImage={
            caseStudy.attributes.SEO.SEOImage
              ? caseStudy.attributes.SEO.SEOImage.media.data.attributes.url
              : ''
          }
          description={
            caseStudy.attributes.SEO.metaDescription
              ? caseStudy.attributes.SEO.metaDescription
              : ''
          }
          title={
            caseStudy.attributes.SEO.metaTitle
              ? caseStudy.attributes.SEO.metaTitle
              : ''
          }
        />
      )}
      {imagePreloader.isLoading && <Preloader />}
      <div ref={rootRef} className={'case-study-components'}>
        {caseStudy &&
          caseStudy.attributes.caseStudyComponents.map((comp, i) => (
            <div className={styles.csOuterWrapper} key={`cs-component-${i}`}>{getDynamicComponent(comp, i)}</div>
          ))}
      </div>

      <Footer />
    </ScrollablePage>
  );
};

export default forwardRef(CaseStudy);
