import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const DesktopMobileOption2 = ({ desktop, mobile1, mobile2, border }, ref) => {
  return (
    <div ref={ref} className={`${styles.desktopMobileWrapper} desktop-mobile-option-02`}>
      <img
      alt="desktop frame"
        className={`${styles.frame} ${styles.desktopFrame} ${
          border && styles.border
        }`}
        src={desktop}
      />
      <img
      alt="mobile frame 1"
        src={mobile1}
        className={`${styles.frame} ${styles.mobileFrame1} ${
          border && styles.border
        }`}
     / >
      <img
      alt="mobile frame 2"
      src={mobile2}
        className={`${styles.frame} ${styles.mobileFrame2} ${
          border && styles.border
        }`}
      / >
    </div>
  );
};

export default forwardRef(DesktopMobileOption2);
