import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const DesktopMobileOption1 = ({ desktop, mobile, border }, ref) => {
  return (
    <div
      ref={ref}
      className={`${styles.desktopMobileWrapper} desktop-mobile-option-01`}
    >
      <img
        alt='desktop frame'
        className={`${styles.frame} ${styles.desktopFrame} ${
          border && styles.border
        }`}
        src={desktop}
      />

      <img
        alt='mobile frame'
        src={mobile}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        }`}
      />
    </div>
  );
};

export default forwardRef(DesktopMobileOption1);
