import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';
import AspectRatioContainer from '../../aspect-ratio-container';
const YoutubeVideo = ({ url }, ref) => {
  return (
    <div className={styles.youtubeVideo}>
      <AspectRatioContainer aspectRatio={'16/9'}>
        <iframe
          className={styles.frame}
          width='100%'
          height='100%'
          src={url}
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
      </AspectRatioContainer>
    </div>
  );
};

export default forwardRef(YoutubeVideo);
