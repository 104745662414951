import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const Testimonial = ({ title, paragraph , backgroundImage}, ref) => {

    let style = {}
    if (backgroundImage) {
        style.backgroundImage = `url(${backgroundImage})`
    }

  return (
    <div className={`${styles.testimonialWrapper} testimonial`} style={style}>
      <div ref={ref} className={`${styles.testimonialBlock}`}>
        <p className={`${styles.testimony} body-XL`}>{paragraph} </p>
        <h5 className={`${styles.title} utility-overline-heavy`}>- {title}</h5>
      </div>
    </div>
  );
};

export default forwardRef(Testimonial);
