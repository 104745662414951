import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const TextBlockOption4 = ({ paragraph1, paragraph2, paragraph3, textColor }, ref) => {
  let colorStyle = {};

  if (textColor) {
    colorStyle.color = textColor;
  }
  return (
    <div ref={ref} className={`${styles.textWrapper} text-option-04`}>
      <div className={`${styles.column}`}>
        <p className={`${styles.paragraph1} body-M`} style={colorStyle}>{paragraph1}</p>
      </div>

      <div className={`${styles.column}`}>
        <p className={`${styles.paragraph2} body-M`} style={colorStyle}> {paragraph2}</p>
      </div>

      <div className={`${styles.column}`}>
        <p className={`${styles.paragraph2} body-M`} style={colorStyle}> {paragraph3}</p>
      </div>
    </div>
  );
};

export default forwardRef(TextBlockOption4);
