import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const TextBlockOption5 = ({ paragraph, textColor }, ref) => {
  let colorStyle = {};

  if (textColor) {
    colorStyle.color = textColor;
  }
  return (
    <div ref={ref} className={`${styles.textWrapper} text-option-05`}>
      <p className={`${styles.paragraph} body-M`} style={colorStyle}>
        {paragraph}
      </p>
    </div>
  );
};

export default forwardRef(TextBlockOption5);
