import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const DesktopOption4 = ({ desktop1, desktop2, border }, ref) => {
  return (
    <div ref={ref} className={`${styles.desktopWrapper} desktop-option-04`}>
      <img
        alt='desktop frame'
        className={`${styles.frame} ${styles.desktopFrame} ${
          border && styles.border
        } ${styles.first}`}
        src={desktop1}
      />
      <img
        alt='desktop frame 2'
        className={`${styles.frame} ${styles.desktopFrame} ${
          border && styles.border
        } ${styles.second}`}
        src={desktop2}
     />
    </div>
  );
};

export default forwardRef(DesktopOption4);
