import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const TextBlockOption1 = ({ title, paragraph1, paragraph2, textColor }, ref) => {

  let colorStyle = {};

  if (textColor) {
    colorStyle.color = textColor;
  }
  return (
    <div ref={ref} className={`${styles.textWrapper} text-option-01`}>
      <div className={`${styles.left}`}>
        <p className={`${styles.paragraph1} body-XL`} style={colorStyle}>{paragraph1}</p>
      </div>

      <div className={`${styles.right}`}>
        <h3 className={`${styles.title} utility-overline-heavy`} style={colorStyle}>{title}</h3>
        <p className={`${styles.paragraph2} body-M`} style={colorStyle}> {paragraph2}</p>
      </div>
    </div>
  );
};

export default forwardRef(TextBlockOption1);
