import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const DesktopOption1 = (
  { desktop1, desktop2, desktop3, desktop4, desktop5, desktop6, border },
  ref
) => {
  return (
    <div ref={ref} className={`${styles.desktopWrapper} desktop-option-01`}>
      <div className={`${styles.row} ${styles.first}`}>
        <img
          alt="desktop frame 1"
          src={desktop1}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
        <img
          alt="desktop frame 2"
          src={desktop2}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
        <img
          alt="desktop frame 3"
          src={desktop3}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
      </div>
      <div className={`${styles.row} ${styles.second}`}>
      <img
          alt="desktop frame 4"
          src={desktop4}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
         <img
          alt="desktop frame 5"
          src={desktop5}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
         <img
          alt="desktop frame 6"
          src={desktop6}
          className={`${styles.frame} ${styles.desktopFrame} ${
            border && styles.border
          }`}
        />
      </div>
    </div>
  );
};

export default forwardRef(DesktopOption1);
