import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const TextBlockOption2 = ({ title, paragraph, textColor }, ref) => {

  let colorStyle = {};

  if (textColor) {
    colorStyle.color = textColor;
  }
  return (
    <div ref={ref} className={`${styles.textWrapper} text-option-02`}>
      <div className={`${styles.left}`}>
        <h3 className={`${styles.title} title-M`} style={colorStyle}>{title}</h3>
        <div className={`${styles.underline}`} />
      </div>

      <div className={`${styles.right}`}>
        <p className={`${styles.paragraph} body-M`} style={colorStyle}> {paragraph}</p>
      </div>
    </div>
  );
};

export default forwardRef(TextBlockOption2);
