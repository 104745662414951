import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const ImageGrid2 = ({ image1, image2, image3, image4 }, ref) => {
  return (
    <div ref={ref} className={`${styles.imageWrapper} image-grid-02`}>
      <div className={`${styles.row}`}>
        <img alt='frame 1' className={`${styles.imageBlock} `} src={image1} />
        <img alt='frame 2' className={`${styles.imageBlock}`} src={image2} />
      </div>
      <div className={`${styles.row}`}>
        <img alt='frame 3' className={`${styles.imageBlock} `} src={image3} />
        <img alt='frame 4' className={`${styles.imageBlock} `} src={image4} />
      </div>
    </div>
  );
};

export default forwardRef(ImageGrid2);
