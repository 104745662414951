import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const DesktopOption2 = ({ desktop, border }, ref) => {
  return (
    <div ref={ref} className={`${styles.desktopWrapper} desktop-option-02`}>
      <img
        alt='desktop frame'
        className={`${styles.frame} ${styles.desktopFrame} ${
          border && styles.border
        }`}
        src={desktop}
      />
    </div>
  );
};

export default forwardRef(DesktopOption2);
