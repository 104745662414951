import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const MobileOption3 = ({ mobile1, mobile2, mobile3, border }, ref) => {
  return (
    <div ref={ref} className={`${styles.mobileWrapper} mobile-option-06`}>
      <img
        alt='frame 1'
        src={mobile1}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        }`}
      />
      <img
        alt='frame 2'
        src={mobile2}
        style={{ backgroundImage: `url(${mobile2})` }}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        }`}
      />
      <img
        alt='frame 3'
        src={mobile3}
        style={{ backgroundImage: `url(${mobile3})` }}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        }`}
      />
    </div>
  );
};

export default forwardRef(MobileOption3);
