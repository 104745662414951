import gsap, {Power4} from 'gsap';
import { isMobile } from 'react-device-detect';
const animateHeadComponent = (node) => {
    if (!node || !node.current) return;
    // gsap.from(document.querySelector('.header').children, {
    //     duration: 1,
    //     scale: 2,
    //   });
    const duration = 1.5;
    const easing = Power4.easeOut;
    const tl = gsap.timeline({onComplete: () => {
    }});
  
    const tChildElements = gsap.from(node.current.querySelectorAll('.header .anim'), {
        duration,
        ease:easing,
        opacity:0,
        x: -30,
        stagger:0.07
    });

    const tBackgroundContainer = gsap.from(node.current.querySelector('.header'), {
        duration,
        ease:easing,
        scale:1.05,
        rotate:1
    });

    tl.add(tBackgroundContainer, 0);
    tl.add(tChildElements, 0);
};


const animateComponentFadeIn = (node, scrollBarRef) => {
    if (!node || !node.current) return;
    const duration = 1;
    const easing = Power4.easeOut;
    node.current.querySelectorAll('.case-study-components>*').forEach((item, i) => {

        if (i === 0) return; // skip header

        // here we define a timeline with one scrolltrigger, that triggers multiple tweens for copy and images respectively
        const tl = gsap.timeline({ scrollTrigger: {
            trigger: item,
            start: `top ${isMobile ? '70%' : '40%'}`,
            scroller: scrollBarRef.current,
            // markers:true
        }});

        
        const componentBlock = gsap.from(item, {
            duration,
            ease: easing,
            y:20,
            opacity:0,
            clearProps: 'all'
        });

        tl.add(componentBlock, 0);
    });

};

export {animateHeadComponent, animateComponentFadeIn};