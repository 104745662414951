import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const MobileOption1 = ({ mobile1, mobile2, mobile3, border }, ref) => {
  return (
    <div ref={ref} className={`${styles.mobileWrapper} mobile-option-01`}>
      <img
        alt='frame 1'
        src={mobile1}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        } ${styles.first}`}
      />
      <img
        alt='frame 2'
        src={mobile2}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        } ${styles.second}`}
      />
      <img
        alt='frame 3'
        src={mobile3}
        className={`${styles.frame} ${styles.mobileFrame} ${
          border && styles.border
        } ${styles.third}`}
      />
    </div>
  );
};

export default forwardRef(MobileOption1);
