import React, { useEffect, useState, useRef, forwardRef } from 'react';
import * as styles from './index.module.scss';

const ImageSingle = ({ image }, ref) => {
  return (
    <div ref={ref} className={`${styles.imageWrapper} image-single`}>
      <img alt='frame' className={`${styles.imageBlock}`} src={image} />
    </div>
  );
};

export default forwardRef(ImageSingle);
